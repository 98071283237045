<template>
  <div>
    <el-dialog
      @close="closePanel"
      width="45%"
      :title="this.form.id ? '编辑品牌签约信息' : '新增品牌签约信息'"
      :visible.sync="dialogshow"
      :close-on-click-modal="modal"
    >
      <el-form
        :model="form"
        label-width="110px"
        :rules="rules"
        ref="myForm"
        required
      >
        <el-form-item label="品牌名称：" prop="brandName">
          <el-input
            placeholder="请输入品牌名称"
            v-model="form.brandName"
          ></el-input>
        </el-form-item>

        <el-form-item label="签约主体：" prop="companyName">
          <el-input
            placeholder="请输入签约主体"
            v-model="form.companyName"
          ></el-input>
        </el-form-item>

        <el-form-item label="联系电话：" prop="companyPhone">
          <el-input
            placeholder="请输入联系电话"
            v-model="form.companyPhone"
          ></el-input>
        </el-form-item>
        <el-form-item label="品牌slogan：" prop="slogan">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入品牌slogan"
            v-model="form.slogan"
          ></el-input>
        </el-form-item>
        <el-form-item label="品牌介绍：" prop="introduces">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入品牌介绍"
            v-model="form.introduces"
          ></el-input>
        </el-form-item>
        <el-form-item label="品牌Logo：" prop="licenseLogo">
          <el-upload
            class="x-single-upload"
            drag
            ref="upload"
            action="#"
            name="file"
            accept=".jpg, .jpeg, .png"
            :show-file-list="false"
            :file-list="licenseLogoList"
            :on-change="setLicenseLogoList"
            :before-upload="beforeLicenseUpload"
            :http-request="doLicenseLogoUpload"
          >
            <el-image
              v-if="form.licenseLogo"
              style="width: 360px; height: 180px"
              :src="form.licenseLogo"
              fit="contain"
            ></el-image>
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              （支持PNG和JPG格式图片，图片大小不超过5M）
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="品牌宣传图：" prop="advertisement">
          <el-upload
            class="x-single-upload"
            drag
            ref="upload"
            action="#"
            name="file"
            accept=".jpg, .jpeg, .png"
            :show-file-list="false"
            :file-list="licenseList"
            :on-change="setLicenseList"
            :before-upload="beforeLicenseUpload"
            :http-request="doLicenseUpload"
          >
            <el-image
              v-if="form.advertisement"
              style="width: 360px; height: 180px"
              :src="form.advertisement"
              fit="contain"
            ></el-image>
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              （支持PNG和JPG格式图片，图片大小不超过5M）
            </div>
          </el-upload>
        </el-form-item>
      </el-form>

      <span slot="footer" class="btn">
        <el-button @click="closePanel" size="small">取 消</el-button>
        <el-button type="primary" @click="doValidate" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { FileUpload } from "@/api/common";
import { BrandCreate, BrandUpdate, BrandDetail } from "@/api/module/brand";
let Brarules = {
  brandName: [
    {
      required: true,
      message: "请输入品牌名称",
      trigger: "blur",
    },
  ],
  companyName: [
    {
      required: true,
      message: "请输入签约主体",
      trigger: "blur",
    },
  ],
  slogan: [
    {
      required: true,
      message: "请输入品牌slogan",
      trigger: "blur",
    },
  ],
  introduces: [
    {
      required: true,
      message: "请输入品牌介绍",
      trigger: "blur",
    },
  ],
  companyPhone: [
    {
      required: false,
      message: "请输入联系电话",
      trigger: "blur",
    },
  ],
  licenseLogo: [
    {
      required: true,
      message: "请上传品牌Logo",
      trigger: "change",
    },
  ],
  advertisement: [
    {
      required: true,
      message: "请上传品牌宣传图片",
      trigger: "change",
    },
  ],
};

export default {
  name: "SignPanel",
  props: ["dialogshow", "id"],
  data() {
    return {
      modal: false,
      licenseList: [],
      licenseLogoList: [],
      form: {
        brandName: "",
        companyName: "",
        companyPhone: "",
        slogan: "",
        introduces: "",
        advertisement: "",
        licenseLogo: "",
      },
      rules: Brarules,
    };
  },
  created() {},
  methods: {
    //   获取某项编辑信息
    async getDetail(id) {
      const r = await BrandDetail(id);
      console.log(r);
      this.form = {
        id: r.id,
        brandName: r.brandName,
        companyName: r.companyName,
        companyPhone: r.companyPhone,
        introduces: r.introduces,
        slogan: r.slogan,
        licenseLogo: r.logo,
        advertisement: r.advertisement[0],
      };
    },

    doValidate() {
      let f = this.form;
      //   新增
      let oData = {
        brandName: f.brandName,
        companyName: f.companyName,
        companyPhone: f.companyPhone,
        slogan: f.slogan,
        introduces: f.introduces,
        advertisement: [f.advertisement],
        logo: f.licenseLogo,
      };
      this.$refs.myForm.validate(async (val) => {
        if (val) {
          if (this.form.id) {
            const r = await BrandUpdate(this.form.id, oData);
          } else {
            const r = await BrandCreate(oData);
          }
          //   全局验证通过;
          this.$message({
            type: "success",
            message: this.form.id ? "编辑成功" : "创建成功",
            duration: 1300,
          });
          this.$emit("update:dialogshow", false);
          //调用父组件的方法
          this.$emit("updata");
          this.$refs["myForm"].resetFields(); // 在初始化所有字段
        } else {
          //全局验证不通过
        }
      });
    },
    closePanel() {
      this.form = {
        brandName: "",
        companyName: "",
        companyPhone: "",
        slogan: "",
        introduces: "",
        advertisement: "",
        licenseLogo: "",
      };
      this.$emit("update:dialogshow", false);
      this.$refs.myForm.resetFields();
    },
    // 选择轮播图
    setLicenseList(file, fileList) {
      this.licenseList = fileList;
    },
    // 限制轮播图大小与格式
    beforeLicenseUpload(file) {
      if (["jpeg", "png", "jpg"].indexOf(file.type.split("/")[1]) < 0) {
        this.$message.error("上传图片只能是 JPG或PNG 格式!");
        return false;
      }
      const sizeLimit = file.size / 1024 / 1024 < 5;
      if (!sizeLimit) {
        this.$message.error("上传图片大小不能超过 5MB!");
        return false;
      }
      return true;
    },

    // 上传轮播图
    doLicenseUpload() {
      let oData = new FormData();
      const oFileList = this.licenseList;
      console.log("ofle", oFileList);
      const len = oFileList.length;
      for (let i = 0; i < len; i++) {
        oData.append("file", oFileList[i].raw);
      }
      FileUpload(oData).then((r) => {
        this.form.advertisement = r.data.file.url;
        this.$refs["myForm"].clearValidate("advertisement");
      });
      this.licenseList = [];
    },

    // 选择轮播图
    setLicenseLogoList(file, fileList) {
      this.licenseLogoList = fileList;
    },

    // 上传轮播图
    doLicenseLogoUpload() {
      let oData = new FormData();
      const oFileList = this.licenseLogoList;
      console.log("ofle", oFileList);
      const len = oFileList.length;
      for (let i = 0; i < len; i++) {
        oData.append("file", oFileList[i].raw);
      }
      FileUpload(oData).then((r) => {
        this.form.licenseLogo = r.data.file.url;
        this.$refs["myForm"].clearValidate("licenseLogo");
      });
      this.licenseLogoList = [];
    },
  },
};
</script>

